import styled from 'styled-components';
import {} from '../../constants/theme';

export const HintMenu = styled.div`
  button {
    background-color: transparent;
    border: none;
    color: #000000;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 15px;
    border-bottom: 2px solid #eb3c96;
    text-transform: uppercase;
    margin: 0 10px;
    padding: 0;
    &:focus {
      outline: none;
    }
    &:hover {
      border-bottom: 2px solid #A52A69;
    }
    &:disabled {
      color: #c1c1c1;
      border-bottom: 2px solid #c1c1c1;
    }
  }
  .action-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .ok-btn {
    background-color: #eb3c96;
    height: 38px;
    width: 94px;
    color: #ffffff;
    font-size: 11px;
    font-weight: 900;
    text-align: center;
    margin-right: -10px;
    &:hover {
      background-color: #A52A69;
    }
  }
  svg {
    height: 30px;
    width: 30px;
    color: #eb3c96;
    float: right;
    margin-top: -40px;
    margin-right: -40px;
    cursor: pointer;
    &:hover {
      color: #A52A69;
    }
  }
  @media (max-width: 1024px) {
    .header-control {
      position: fixed;
      width: 100vw;
      height: 66px;
      top: 0;
      right: 0;
      background-color: white;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-bottom: 1px solid #d5d5d9;
      svg {
        margin-top: 0;
        margin-right: 20px;
      }
    }
    .ok-btn {
      margin: 0;
    }
  }
`;
export const Button = styled.button``;
export const H3 = styled.h3``;
export const ModalStyle = styled.div`
  h4 {
    color: #503291;
    font-size: 30px;
    line-height: 36px;
    font-weight: 900;
    &:hover {
      color: #A52A69;
      text-decoration: underline;
    }
  }
  .modal-header {
    border: none;
    align-items: center;
    &.top-section {
      margin-bottom: 25px;
    }
  }
  .phoneContent {
    display: none;
  }
  @media (min-width: 1301px) {
    .align-cont {
      padding-bottom: 0 !important;
      margin-top: -10px;
    }
  }
  .condition-flow {
    column-count: 3;
    position: relative;
    ::before {
      content: '';
      position: absolute;
      width: 1px;
      background-color: #d5d5d9;
      top: 0;
      left: 32.5%;
      height: 100%;
    }
    ::after {
      content: '';
      position: absolute;
      width: 1px;
      background-color: #d5d5d9;
      top: 0;
      right: 33.5%;
      height: 100%;
    }
  }
  .condition-col {
    padding-bottom: 30px;
    display: inline-block;
    width: 100%;
    .parent-button {
      background-color: transparent;
      text-align: start;
      border: none;
      color: #503291;
      font-size: 18px;
      line-height: 32px;
      font-weight: 900;
      margin-bottom: 20px;
      &:focus {
        outline: none;
      }
      &:hover {
        color: #A52A69;
        text-decoration: underline;
      }
    }
  }
  .sub-conditions {
    display: inline-block;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 16px;
    line-height: 27px;
    button {
      display: inline-block;
      white-space: nowrap;
      background-color: transparent;
      border: none;
      color: #000000;
      font-size: 16px;
      line-height: 27px;
      font-weight: 300;
      &:focus {
        outline: none;
      }
      &.active {
        color: #ffffff;
        background-color: #eb3c96;
      }
      &:hover {
        color: #A52A69;
        text-decoration: underline;
      }
    }
  }
  @media (max-width: 1024px) {
    .phoneContent {
      display: flex;
    }
    h4 {
      padding-top: 40px;
    }
    .sub-conditions {
      margin-bottom: 20px;
    }
    .condition-flow {
      margin: 0;
      column-count: 1;
      ::before {
        display: none;
      }
      ::after {
        display: none;
      }
    }
    .has-children {
      display: flex;
      justify-content: space-between;
      align-items: center;
      svg {
        height: 30px;
        width: 30px;
        color: #eb3c96;
      }
    }
    .modal-header {
      padding: 0;
      .deskContent {
        display: none;
      }
    }
    .condition-col {
      padding: 0;
      border-right: none;
      border-bottom: 1px solid #d5d5d9;
      .parent-button {
        font-size: 22px;
        margin-bottom: 30px;
        margin-top: 30px;
        &:hover {
          color: #A52A69;
          text-decoration: underline;
        }
      }
    }
    .footer {
      position: fixed;
      bottom: 0;
      background-color: #ffffff;
      box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05),
        0 -10px 20px 0 rgba(0, 0, 0, 0.1);
      width: 100%;
      height: 119px;
      left: 0;
      .modal-header {
        height: 100%;
        .ok-btn {
          width: 86%;
        }
      }
      .phoneContent {
        width: 100%;
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-content: space-evenly;
        div {
          text-align: center;
          display: flex;
          justify-content: space-around;
          width: 100%;
        }
        @media (max-width: 480px) {
          div {
            justify-content: center;
          }
        }
      }
    }
  }
`;
export const ButtonSubCond = styled.div``;
