/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Consumer } from 'store/createContext';
import { Row, Col, Collapse } from 'reactstrap';
import { HintMenu, Button, ModalStyle } from './modal-conditions.css';
import { IoMdClose } from 'react-icons/io';
import { FaPlusCircle, FaMinusCircle } from 'react-icons/fa';
import { trackEvent } from '../../helpers/tagManager';

const ModalConditionsContainer = ({
  conditionsList,
  title,
  all,
  clear,
  ok,
  onSelected,
  allSelected,
  handleModal,
  preselectedConditions,
  preselectedHealth,
}) => {
  const [selected, setSelected] = useState([]);
  const [health, setHealth] = useState('');
  const [collapseCON, setCollapseCON] = useState(
    conditionsList.map(() => true)
  );
  const [displayValues, setDisplayValues] = useState({});

  let healthtyState = '';

  useEffect(() => {
    setPresSelected(preselectedConditions, preselectedHealth);
  }, []);

  const toggleCON = index => {
    let collapse = collapseCON.slice();
    collapse[index] = !collapseCON[index];
    setCollapseCON(collapse);
  };

  const toggle = item => {
    if (selected.includes(item.InternalValue)) {
      const removeSelected = selected.slice();
      removeSelected.splice(removeSelected.indexOf(item.InternalValue), 1);
      setSelected(removeSelected);
      if (!item.Children) {
        delete displayValues[item.InternalValue];
      }
      if (item.Children) {
        item.Children.forEach(child => {
          if (removeSelected.includes(child.InternalValue)) {
            removeSelected.splice(
              removeSelected.indexOf(child.InternalValue),
              1
            );
            setSelected(removeSelected);
            delete displayValues[child.InternalValue];
          }
        });
      }
    } else {
      const addSelected = selected.slice();
      addSelected.push(item.InternalValue);
      setSelected(addSelected);
      if (!item.Children) {
        displayValues[item.InternalValue] = item.DisplayValue;
      }
      if (item.Children) {
        item.Children.forEach(child => {
          addSelected.push(child.InternalValue);
          setSelected(addSelected);
          displayValues[child.InternalValue] = child.DisplayValue;
        });
      }
    }
    trackEvent({
      category: 'SiteSearch',
      action: 'Suggestion_Click',
      label: item.DisplayValue,
      eventActionType: 'Conditions',
    });
  };

  const clearAll = () => {
    setSelected([]);
    setHealth('');
    setDisplayValues({});
    healthtyState = '';
    allSelected();
  };

  const setPresSelected = (preselected, healthValue) => {
    let newData = {};
    if (preselected && Array.isArray(preselected) && preselected.length) {
      newData.selected = preselected;
      if (Object.values(displayValues).length === 0) {
        conditionsList.forEach(item => {
          if (preselected.includes(item.InternalValue) && !item.Children) {
            displayValues[item.InternalValue] = item.DisplayValue;
          }
          if (item.Children && Array.isArray(item.Children)) {
            item.Children.forEach(subItem => {
              if (preselected.includes(subItem.InternalValue)) {
                displayValues[subItem.InternalValue] = subItem.DisplayValue;
              }
            });
          }
        });
      }
    }
    if (healthValue && health !== healthValue) {
      healthtyState = healthValue;
      newData.health = healthtyState;
    }

    if (Object.keys(newData).length) {
      setSelected(newData.selected);
    }
  };

  return (
    <Consumer>
      {() => (
        <ModalStyle>
          <HintMenu className={'header-mobile'}>
            <div className={'header-control'}>
              <IoMdClose onClick={() => handleModal()} />
            </div>
            <Row className={'modal-header' + ' top-section'}>
              <Col lg={5}>
                <h4>{title}</h4>
              </Col>
              <Col className={'deskContent' + ' action-section'} lg={7}>
                <button
                  onClick={() => {
                    clearAll(), handleModal();
                  }}
                >
                  {all}
                </button>
                <button
                  disabled={selected.length === 0 && health === ''}
                  onClick={clearAll}
                >
                  {clear}
                </button>
                <button
                  className={'ok-btn'}
                  onClick={() => {
                    (onSelected &&
                      onSelected(
                        selected,
                        Object.values(displayValues),
                        healthtyState
                      )) ||
                      handleModal();
                  }}
                >
                  {ok}
                </button>
              </Col>
            </Row>
          </HintMenu>
          <main className={'condition-flow'}>
            {conditionsList.map((condition, index) => {
              return (
                <section
                  key={index}
                  className={
                    'condition-col' + (index === 2 ? ' align-cont' : '')
                  }
                >
                  <div
                    className={
                      (condition.Children && condition.Children.length
                        ? ' has-children '
                        : '') +
                      (selected.includes(condition.InternalValue)
                        ? ' active '
                        : '')
                    }
                  >
                    <Button
                      className={'parent-button'}
                      onClick={() => toggle(condition)}
                    >
                      {condition.DisplayValue}
                    </Button>
                    <div className={'phoneContent'}>
                      {collapseCON[index] === true ? (
                        <FaMinusCircle onClick={() => toggleCON(index)} />
                      ) : (
                        <FaPlusCircle onClick={() => toggleCON(index)} />
                      )}
                    </div>
                  </div>
                  <Collapse
                    className={'deskContent' + 'phoneContent'}
                    isOpen={collapseCON[index]}
                  >
                    {condition.Children && condition.Children.length && (
                      <div className={'sub-conditions'}>
                        {condition.Children.map((subCondition, subIndex) => {
                          return (
                            <div
                              style={{ display: 'inline-block' }}
                              key={'condition_ ' + index + subIndex}
                            >
                              <Button
                                onClick={() => toggle(subCondition, condition)}
                                className={
                                  'subConditionBtn' +
                                  (selected.includes(subCondition.InternalValue)
                                    ? ' active '
                                    : '')
                                }
                              >
                                {subCondition.DisplayValue}
                              </Button>
                              {subIndex < condition.Children.length - 1
                                ? ' | \u00a0'
                                : null}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </Collapse>
                </section>
              );
            })}
          </main>
          <HintMenu className={'footer'}>
            <Row className={'modal-header'}>
              <div className={'phoneContent'}>
                <div>
                  <button
                    onClick={() => {
                      allSelected(), clearAll(), handleModal();
                    }}
                  >
                    {all}
                  </button>
                  <button
                    disabled={selected.length === 0 && health === ''}
                    onClick={() => clearAll()}
                  >
                    {clear}
                  </button>
                </div>
                <button
                  className={'ok-btn'}
                  onClick={() => {
                    (onSelected &&
                      onSelected(
                        selected,
                        Object.values(displayValues),
                        healthtyState
                      )) ||
                      handleModal();
                  }}
                >
                  {ok}
                </button>
              </div>
              <Col className={'deskContent' + ' action-section'}>
                <button
                  onClick={() => {
                    allSelected(), clearAll(), handleModal();
                  }}
                >
                  {all}
                </button>

                <button
                  disabled={selected.length === 0 && health === ''}
                  onClick={() => clearAll()}
                >
                  {clear}
                </button>

                <button
                  className={'ok-btn'}
                  onClick={() => {
                    (onSelected &&
                      onSelected(
                        selected,
                        Object.values(displayValues),
                        healthtyState
                      )) ||
                      handleModal();
                  }}
                >
                  {ok}
                </button>
              </Col>
            </Row>
          </HintMenu>
        </ModalStyle>
      )}
    </Consumer>
  );
};

ModalConditionsContainer.propTypes = {
  conditionsList: PropTypes.array,
  preselectedConditions: PropTypes.array,
  preselectedHealth: PropTypes.string,
  healthVol: PropTypes.array,
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  conditions: PropTypes.string,
  all: PropTypes.string,
  clear: PropTypes.string,
  accept: PropTypes.string,
  ok: PropTypes.string,
  onSelected: PropTypes.func,
  allSelected: PropTypes.func,
  handleModal: PropTypes.func,
};

export default ModalConditionsContainer;
